@use '../../sass/abstracts' as *;

.container {
    background-color: $color-white;
    position: sticky;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    box-shadow: $box-shadow-regular-grey;
    z-index: 1;
    max-height: 70px;

    @include mobile {
        padding: 5px;
    }
}

.logo-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-left: 40px;

    > img {
        height: 70px;
        width: auto;   
    }

    @include mobile {
        margin-left: 0px;

        > img {
            height: 40px;
        }
    }

    @include tablet {
        margin-left: 0px;

        > img {
            height: 40px;
        }
    }

    @include laptop {
        margin-left: 10px;
    }
}

.subcontainer {
    display: flex;
    align-items: center;
    position: relative;
    padding: $padding-horizontal-large;

    @include mobile {
        display: none;
    }

    @include tablet {
        padding: 5px;
    }

    @include laptop {
        padding: $padding-horizontal-regular;
    }

    a {        
        @include tablet {
            padding: 10px 5px;
        }
        
        @include laptop {
            padding: 10px;
        }
    }
}

.mobile-menu {
    display: none;
    margin-right: 10px;

    @include mobile {
        display: block;
    }
}

.login-or-signup-button {
    line-height: 14px;

    @include tablet {
        padding: 10px!important;
    }
}
