@use '../../../../sass/abstracts' as *;

.container {
    box-shadow: $box-shadow-regular-grey-dark;
    border-radius: $border-radius-regular;
    padding: $padding-equal-regular;
    background-color: $color-white;

    > button {
        width: 40%;
        margin: 20px 0px 0px;
        font-size: $font-size-large;
    }
}

.form-table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @include mobile {
        display: flex;
        flex-direction: column;
    }
}

.form-group {
    input {
        padding: $padding-horizontal-regular;
        border: 4px solid $color-grey-light;
        width: 100%;
        font-size: $font-size-large;
        color: $color-grey-dark;
        height: 60px !important;

        @include mobile {
            font-size: $font-size-regular;
            height: 50px !important;
        }

        &::placeholder {
            color: $color-grey;
            font-family: $font-primary-book;
        }
    }

    label {
        margin-left: 10px;
        color: $color-grey-dark;

        @include mobile {
            font-size: $font-size-regular;
        }
    }

    input:global(.is-invalid) {
        border-color: $color-error;
    }

    :global(.invalid-feedback) {
        color: $color-error;
        margin-left: 10px;
    }
}

.button-container {
    display: grid;
    grid-template-columns: 40% 40%;
    gap: 20px;

    @include mobile {
        display: flex;
        flex-direction: column;
    }
    > button {
        font-size: $font-size-large;
    }
}

.form-feedback{
    color: $color-error;

    @include mobile {
        font-size: $font-size-regular;
    }
}

.error {
    text-align: center;
    color: $color-error;
    margin: 20px auto 0px;

    @include mobile {
        font-size: $font-size-regular;
    }
}

.password-changed-text-container {
    text-align: center;
    margin: 0px auto;
    max-width: 100%;
    padding: $padding-horizontal-large;
    
    @include desktop {
        max-width: 80%;
    }
    
    .password-changed-header {
        font-size: $font-size-xl;
        font-family: $font-primary-extra-bold;
        color: $color-green;
    }

    .password-changed-body {
        font-size: $font-size-large;
        font-family: $font-primary-book;
        color: $color-grey-dark;

        a {
            color: $color-green;
        }
    }

    .complete-button {
        margin: 40px auto 0px;
        padding: $padding-horizontal-xl;
        font-size: $font-size-xl;
    }
}
