@use '../../../../sass/abstracts' as *;

.container {
    width: 60%;
    margin: 0px auto;
    padding: 100px 0px 0px;
    text-align: center;
    counter-reset: accordion;

    > h2 {
        text-align: center;
        font-size: $font-size-2xl;
        color: $color-teal;
        font-family: $font-primary-extra-bold;
    }

    @include laptop {
        width: 85%;
    }

    @include tablet {
        width: 90%;

        padding: 75px 0px 0px;

        h2 {
            font-size: $font-size-xl;
            padding-bottom: 10px;
        }
    }

    @include mobile {
        width: 90%;
        padding: 50px 0px 0px;

        h2 {
            font-size: $font-size-2xl-mobile;
            padding-bottom: 10px;
        }
    }
}

.button {
    border: none;
    background: none;
}
