@use '../../../../sass/abstracts' as *;

.container {
    box-shadow: $box-shadow-regular-grey-dark;
    border-radius: $border-radius-regular;
    padding: $padding-equal-regular;
    background-color: $color-white;

    .submit-button {
        width: 40%;
        margin: 20px 0px 0px;
        font-size: $font-size-large;
    }
}

.form-table {
    display: grid;
    grid-template-columns: minmax(40%, 1fr) minmax(40%, 1fr);
    gap: 20px;

    @include mobile {
        display: flex;
        flex-direction: column;
    }

    @include tablet {
        display: flex;
        flex-direction: column;
    }
}

.form-group {
    input {
        padding: $padding-horizontal-regular;
        border: 4px solid $color-grey-light;
        width: 100%;
        font-size: $font-size-large;
        color: $color-grey-dark;
        font-family: $font-primary-medium;
        height: 60px !important;

        &::placeholder {
            color: $color-grey;
            font-family: $font-primary-book;
        }

        @include mobile {
            font-size: $font-size-regular;
            height: 50px !important;
        }
    }
    
    label {
        margin-left: 10px;
        color: $color-grey-dark;
        font-size: $font-size-small;
    }
    
    input:global(.is-invalid) {
        border: 2px solid $color-error;
    }

    :global(.invalid-feedback) {
        color: $color-error;
        margin-left: 10px;
    }
}

.purchase-amount-input-container {
    position: relative;
    
    &::before {
        position: absolute;
        content: '$';
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        color: $color-grey-dark;
        font-size: $font-size-large;
    }

    input {
        padding-left: 30px;
    }
}

.outlet-search-container {    
    position: relative;

    &.is-invalid {
        border-color: $color-error !important;
        box-shadow: none !important;
        border: 2px solid;
    }
    
    &::after {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.4em solid;
        border-right: 0.4em solid transparent;
        border-bottom: 0;
        border-left: 0.4em solid transparent;
    }

    > input {
        color: $color-grey-dark;
        width: 100%;
        overflow: hidden;
        display: block;
        outline: none;
        border-radius: 4px;
          
        &.is-toggled {
            border-color: rgba(var(--bs-primary-rgb), 0.55) !important;
            box-shadow: 0px 0px 0px 2px var(--bs-focus-ring-color) !important;
        }
        
        @include mobile {
            font-size: $font-size-regular;
        }
    }
}

.dropdown-menu {
    display: none;
    position: absolute;
    border: 1px solid $color-grey;
    border-radius: $border-radius-regular;
    z-index: 1;
    background-color: $color-white;
    max-height: 50vh;
    width: 100%;
    overflow: hidden visible;

    @include mobile {
     width: 100%;
    }

    &.is-open {
        display: block;
    }
}

.dropdown-item {
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: start;
    font-size: $font-size-large;
    font-family: $font-primary-book;
    padding: 5px 30px !important;
    margin: auto;

    &:hover {
        cursor: pointer;
        background-color: $color-grey-light;
    }

    @include mobile {
        font-size: $font-size-regular;
        white-space: pre-wrap !important;
    }
}

.dropdown-item-header {
    font-size: $font-size-large !important;
    font-family: $font-primary-book;
    color: $color-grey !important;
    padding: 5px 10px !important;
    user-select: none;
    -webkit-user-select: none;

    @include mobile {
        font-size: $font-size-regular !important;
        white-space: pre-wrap !important;
    }
}

.upload-file-button {
    display: block;
    background-color: $color-white !important;
    padding-left: 10px;
    border: 4px solid $color-grey-light;
    border-radius: 4px;
    width: 100%;
    font-size: $font-size-large;
    color: $color-grey-dark;
    text-align: start;
    height: 60px !important;

    @include mobile {
        font-size: $font-size-regular;
        height: 50px !important;
    }

    &.is-invalid {
        border-color: $color-error !important;
        box-shadow: none !important;
        border: 2px solid;
    }

    > div {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        align-items: center;
    }

    .file-name {
        overflow: 'hidden';
    }
}

.upload-file-preview {
    height: 50px;

    @include mobile {
        height: 40px;
    }
}

.submission-complete-text-container {
    text-align: center;
    margin: 0px auto;
    max-width: 100%;
    padding: $padding-horizontal-large;
    
    @include mobile {
        padding: $padding-horizontal-regular;
    }
    
    @include desktop {
        max-width: 80%;
    }
    
    .submission-complete-header {
        font-size: $font-size-xl;
        font-family: $font-primary-extra-bold;
        color: $color-green;
    }

    .submission-complete-body {
        font-size: $font-size-large;
        font-family: $font-primary-book;
        color: $color-grey-dark;

        a {
            color: $color-green;
        }
    }

    .complete-button {
        margin: 40px auto 0px;
        padding: $padding-horizontal-xl;
        font-size: $font-size-xl;

        @include mobile {
            padding: $padding-horizontal-regular;
        }

        @include tablet {
            padding: $padding-horizontal-large;
            font-size: $font-size-large;
        }
    }
}

.error {
    text-align: center;
    color: $color-error;
    margin: 20px auto 0px;
}