@use '../../sass/abstracts' as *;

.container {
    border-radius: 3px;
    border: none;
    box-shadow: $box-shadow-regular-grey-dark;
    text-align: start;
    width: 100%;
    min-width: min-content;
    display: flex;
    background-color: $color-grey-light;
    height: 150px;

    @include mobile {
        height: 120px;
    }

    > img, 
    > svg {
        width: 200px;
        min-width: 200px;
        height: 100%;
        object-fit: cover;
        padding: 0;

        @include mobile {
            width: 75px;
            min-width: 75px;
            object-fit: contain;
        }

        @include tablet {
            width: 100px;
            min-width: 100px;
            object-fit: contain;
        }

        @include laptop {
            width: 150px;
            min-width: 150px;
            object-fit: contain;
        }
    }

    > svg {
        color: $color-grey-dark;
    }
}

.content-container {
    background-color: $color-white;
    padding: $padding-horizontal-regular;
    padding-right: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @include mobile {
        padding: $padding-horizontal-small;
    }

    @include tablet {
        padding: $padding-horizontal-small;
    }

    > svg {
        width: 50px;
        min-width: 50px;
        color: $color-grey;
        margin: 5px;;

        @include mobile {
            width: 30px;
            min-width: 30px;
        }

        @include desktop {
            margin: 20px;
        }
    }
}

.container:hover {
    .content-container {
        background-color: $color-grey-light;
    }
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    color: $color-grey-dark;
    font-size: $font-size-xl;

    p {
        white-space: initial;
        text-overflow: ellipsis;
        overflow: hidden;
    
        @supports (-webkit-line-clamp: 2) {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
    }

    @include laptop {
        font-size: $font-size-large;
    }

    @include tablet {
        font-size: $font-size-regular;
    }
    
    @include mobile {
        font-size: $font-size-regular;
    }
}

.expired-description {
    color: $color-error;
}