@use '../../sass/abstracts' as *;

.a {
    text-decoration: none;
    padding: $padding-horizontal-regular;
    font-size: $font-size-regular;
    color: $color-grey-dark;
    white-space: nowrap;

    @include tablet {
        padding: $padding-horizontal-small;
        font-size: $font-size-small;
    }

    @include mobile {
        padding: $padding-horizontal-small;
        font-size: $font-size-regular-mobile;
        line-height: $font-size-small-mobile;
    }

    &::before {
        font-family: $font-primary-extra-bold;
        content: attr(data-label);
        visibility: hidden;
        overflow: hidden;
        display: block;
        height: 0;
    }
    
    &:hover {
        font-family: $font-primary-extra-bold;
    }

    &:active {
        color: $color-teal;
        font-family: $font-primary-extra-bold;
    }

    &.is-active {
        color: $color-teal;
        font-family: $font-primary-extra-bold;
    }
}