@font-face {
    font-family: 'foundry-sterling-book';
    src: url(./../assets/fonts/FoundrySterling-Book.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'foundry-sterling-medium';
    src: url(./../assets/fonts/FoundrySterling-Medium.otf) format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'foundry-sterling-demi';
    src: url(./../assets/fonts/FoundrySterling-Demi.otf) format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'foundry-sterling-extra-bold';
    src: url(./../assets/fonts/FoundrySterling-ExtraBold.otf) format('opentype');
    font-weight: 800;
    font-style: normal;
}

/* for home-page-banner-header.svg */
@font-face {
    font-family: 'Righteous-Regular';
    src: url('./../assets/fonts/Righteous-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}