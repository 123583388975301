@use '../../../sass/abstracts' as *;

.container {
    position: relative;
    
    > img {
        width: 100%;
        height: auto;
        -webkit-user-select: none;
        user-select: none;
        -webkit-user-drag: none;
    }

    svg {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        user-select: none;
        -webkit-user-select: none;
        height: 45%;

        @include mobile {
            top: 10%;
            width: 90%;
        }

        @include tablet {
            top: 15%;
            width: 90%;
        }

        @include laptop {
            top: 20px;
        }
    }

    > button {
        position: absolute;
        width: 20vw;
        line-height: 4vw;
        border-radius: 0.8vw;
        padding: 0px;
        font-size: 2vw;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;

        @include tablet {
            font-size: $font-size-large;
            width: 35%;
            bottom: 12%;
            padding: 15px 10px;
            border-radius: 10px;
        }

        @include mobile {
            width: 60%;
            bottom: 8%;
            padding: 10px 5px;
            border-radius: 10px;
            font-size: $font-size-regular !important;
        }
    }
}

.banner-subheader {
    position: absolute;
    width: 55vw;
    font-size: 2.2vw;
    bottom: 40%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-family: $font-primary-book;

    @include mobile {
        bottom: 42%;
        font-size: $font-size-small;
        line-height: $font-size-small;
        width: 60vw;
    }

    @include laptop {
        font-size: $font-size-regular;
    }
    
    @include laptop-large {
        font-size: $font-size-large;
    }

    @include desktop {
        font-size: $font-size-large;
    }
}

.banner-cta-paragraph-container {
    position: absolute;
    width: 50vw;
    font-size: 2vw;
    line-height: 3.5vw;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    text-shadow: 1px 1px 5px $color-white;

    > button {
        border: none;
        background-color: transparent;
        color: $color-grab-green;
        text-shadow: 1px 1px 5px $color-white;

        &:hover {
            text-decoration-line: underline;
        }
    }

    > .banner-grab-gifts-text {
        font-family: $font-primary-extra-bold;
        color: $color-grab-green;
    }

    @include mobile {
        font-size: $font-size-small;
        line-height: 4.5vw;    
        font-size: 2.5vw;
        bottom: 22%;
        width: 60vw;
    }

    @include tablet {
        bottom: 25%;
        font-size: 2.5vw;
    }
}

.redeem-button {
    display: block;
    color: $color-white;
    border-radius: $border-radius-regular;
    background-color: $color-grab-green;
    border: none;
    font-size: $font-size-regular;
    padding: $padding-vertical-regular;
    cursor: pointer;
    transition: $transition-slow-ease-in-out;
    box-shadow: 0px 0px 5px $color-black;

    &:hover {
        background-color: $color-grab-green-dark;
    }
}