@use '../../sass/abstracts' as *;

.container {
    padding: 50px 0px 100px;
    background-image: url('../../assets/images/background-image-v2.webp');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

    > h1 {
        color: $color-green;
        font-size: $font-size-xl;
        text-align: center;
        text-shadow: 1px 1px 1px $color-white;
    }

    > p {
        color: $color-grey-dark;
        font-size: $font-size-large;
        text-align: center;
        font-family: $font-primary-book;
        text-shadow: 1px 1px 1px $color-white;
    }
}

.subcontainer {
    width: 40%;
    margin: 20px auto 0px;

    @include tablet {
        width: 60%;
    }

    @include mobile {
        width: 90%;
    }
    > button {
        background-color: $color-grey-light;
        color: $color-grey;
        padding: $padding-equal-regular;
        border: none;
        border-radius: $border-radius-top-regular;
        box-shadow: 0px -2px 5px 0px $color-grey;

        &:first-of-type {
            margin-left: 20px;
        }

        &.is-active {
            background-color: $color-white;
            color: $color-green;
        }
    }

    @include mobile {
        width: 90%;
    }
}

.tab-content-container {
    border: none;
    border-radius: $border-radius-regular;
    background-color: $color-white;
    padding: $padding-equal-regular;
    box-shadow: $box-shadow-regular-grey-dark;
}