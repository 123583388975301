@use '../../../sass/abstracts' as *;

.container {
    a {
        color: $color-teal;
        text-decoration-line: none;
        margin-left: 10px;

        &:hover {
            text-decoration-line: underline;
        }
    }

    button {
        width: 60%;
        margin: 20px auto 0px;
        font-size: $font-size-large;
    }
}

.form-group {
    input {
        padding: $padding-horizontal-regular;
        border: 4px solid $color-grey-light;
        width: 100%;
        font-size: $font-size-large;
        color: $color-grey-dark;

        &::placeholder {
            color: $color-grey;
            font-family: $font-primary-book;
        }
    }

    label {
        margin-left: 10px;
        margin-top: 10px;
        color: $color-grey-dark;
    }

    &:first-of-type label {
        margin-top: 0px;
    }
    
    input:global(.is-invalid) {
        border: 2px solid $color-error;
    }

    :global(.invalid-feedback) {
        color: $color-error;
        margin-left: 10px;
    }
}

.error {
    text-align: center;
    color: $color-error;
    margin: 20px auto 0px;
}