@use '../../sass/abstracts' as *;

.dropdown {
    position: relative;
    border-radius: 5px;
    padding: 3px 0px;
    border: 1px solid $color-grey-dark;
}

.toggle {
    background-color: transparent !important;
    border: none !important;
    border-radius: $border-radius-regular !important;
    color: $color-black !important;
    display: flex !important;
    overflow: visible !important;
    align-items: center;
    padding: $padding-equal-small !important;
    
    div {
        font-size: $font-size-large;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: $color-white;
        background-color: $color-green;

        @include mobile {
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: $font-size-regular;
        }

        @include tablet {
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: $font-size-regular;
        }
    }

    > img {
        content: url('../../assets/images/hamburger.png');
    }
    
    &::after {
        transition: $transition-fast-ease;
        border-top: 0.6rem solid $color-grey-dark !important;
        border-right: 0.6rem solid transparent !important;
        border-left: 0.6rem solid transparent !important;
        margin-left: 1rem !important;
    }
}

.toggle.isOpen,
.embedded-dropdown-toggle.isOpen {
    &::after {
        transform: rotate(180deg);
    }

    > img {
        content: url('../../assets/images/close.png');
    }
}

.dropdown-menu {
    @include mobile {
        border-radius: 0px !important;
        transform: translate(0px, 42px) !important;
        width: 93vw !important;
        height: calc(100vh - 32px) !important;
    }
}

.dropdown-item {
    font-size: $font-size-regular;
    padding: 1rem 4rem 1rem 2rem !important;
    color: $color-grey-dark !important;

    &:active {
        color: $color-teal !important;
        background-color: transparent !important;
    }

    &__green {
        font-size: $font-size-regular;
        padding: 1rem 4rem 1rem 2rem !important;
        color: $color-white !important;
        background-color: $color-green !important;
    }
}

.embedded-dropdown {
    cursor: pointer;

    &:hover {
        background-color: var(--bs-tertiary-bg);
    }
}

.embedded-dropdown-toggle {
    width: 100%;
    padding: 1rem 4rem 1rem 2rem !important;
    font-size: $font-size-regular !important;
    color: $color-grey-dark !important;

    &:active {
        color: $color-teal !important;
        background-color: transparent !important;
    }
    
    &::after {
        transition: $transition-fast-ease;
        border-top: 0.6rem solid transparent !important;
        border-left: 0.6rem solid $color-grey-dark !important;
        border-bottom: 0.6rem solid transparent !important;
        border-right: 0px !important;
        text-align: center !important;
        position: absolute;
        right: 20px;
    }
}

.embedded-dropdown-toggle:active {
    &::after {
        border-left: 0.6rem solid $color-teal !important;
    }
}

.embedded-dropdown-toggle-menu {
    .dropdown-item {
        &:hover {
            text-decoration-line: underline;
        }
    }
}

.mobile-menu-copyright-container {
    width: 100%;
    margin: 0px auto; 
    padding: $padding-horizontal-small;
    padding-bottom: 10px;
    color: $color-grey-dark;
    font-family: $font-primary-book;
    line-height: $font-size-regular;
    font-size: $font-size-regular-mobile;
    text-align: center;
    position: absolute;
    bottom: 0;
    a {
        color: $color-teal;
        text-decoration-line: none;
    }
}