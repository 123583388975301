@use '../../sass/abstracts' as *;

.container {
    padding: $padding-horizontal-regular;
    background-image: url('../../assets/images/background-image-v2.webp');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

    > h1 {
        font-size: $font-size-2xl;
        color: $color-teal;
        font-family: $font-primary-extra-bold;
        text-align: center;

        @include tablet {
            font-size: $font-size-xl;
        }

        @include mobile {
            font-size: $font-size-2xl-mobile;
        }
    }
}

.subcontainer {
    width: 70%;
    margin: 20px auto;
    background-color: $color-white;
    border-radius: $border-radius-regular;
    border: 3px solid $color-green;
    padding: $padding-equal-regular;
    padding-left: 45px; 
    counter-reset: terms-and-conditions-items;

    @include tablet {
        width: 95%;
        padding-left: 40px; 
    }

    @include mobile {
        width: 95%;
        padding-left: 35px; 
    }
}

.subcontainer-items {
    counter-increment: terms-and-conditions-items;
    counter-reset: alphabet-list-item;
    margin-bottom: 20px;
    position: relative;
    white-space: pre-wrap;

    &:last-child {
        margin-bottom: 0px;
    }

    p {
        font-size: $font-size-large;
        color: $color-grey-dark;

        @include mobile {
            font-size: $font-size-regular;
        }
    }

    p::before {
        content: counter(terms-and-conditions-items) '. ';
        position: absolute;
        left: 0px;
        top: 0px;
        transform: translateX(-100%);
    }

    > span {
        display: flex;
        gap: 10px;
        font-size: $font-size-large;
        color: $color-grey-dark;

        @include mobile {
            font-size: $font-size-regular;

            :nth-child(1) {
                font-size: $font-size-small;
                margin-top: 3px;
            }

            :nth-child(2) {
                word-break: break-all;
            }
        }
    }
}

.alpahabet-list-item {
    counter-increment: alphabet-list-item;
    
    &::before {
        content: counter(alphabet-list-item, lower-alpha) '.';
    }
}

.privacy-policy-container {
    margin-top: 50px;

    @include mobile {
        font-size: $font-size-regular;
    }
    
    > a {
        color: $color-teal !important;

        &:hover {
            text-decoration-line: underline !important;
            cursor: pointer;
        }
    }
}