@use '../abstracts' as *;

body {
  font-weight: normal;
  width: 100%;
  font-size: $default-font-size;
  background-color: $color-white !important;
}

h1 {
  font-size: 3rem;
  
  @include tablet {
    //
  }

  @include mobile {
    //
  }
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  // 
}

h5 {
  // 
}

h6 {
  // 
}

p, .paragraph {
  font-size: 1.6rem;
}