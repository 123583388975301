.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker {
    font-size: 1em !important;
}

.react-datepicker__header {
    padding-top: 0.8em !important;
}

.react-datepicker__month {
    margin: 0.4em 1em !important;
}

.react-datepicker__day-name, 
.react-datepicker__day {
    width: 1.9em !important;
    line-height: 1.9em !important;
    margin: 0.166em !important;
}

.react-datepicker__current-month {
    font-size: 1em !important;
}

.react-datepicker__navigation {
    top: 0.8em !important;
    line-height: 1.7em !important;
    border: 0.3em solid transparent !important;
}

.react-datepicker__navigation--previous {
    left: 1em !important;
}

.react-datepicker__navigation--next {
    right: 1em !important;
}

.react-datepicker__input-container {
    height: min-content !important;
    
    > svg {
        margin: auto;
    }
    
    input {
        padding-left: 2em !important;
        transition: 0.15s ease-in-out;
        border-radius: 0.375rem;

        &:focus {
            border-color: var(--bs-primary-border-subtle) !important;
            border-color: rgba(var(--bs-primary-rgb), 0.55) !important;
            box-shadow: 0px 0px 0px 1.5px var(--bs-focus-ring-color) !important;
            outline: none;
        }
    }

    svg {
        fill: #666 !important;
    }
}

.react-datepicker__calendar-icon {
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
}