@use '../../../../sass/abstracts' as *;
@use '../../../../sass/components' as components;

.container {
    margin: 0px 0px 150px;
    
    h2 {
        font-size: $font-size-xl;
        color: $color-teal;
    }
}

.voucher-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.empty-list-message {
    margin: 20px;
    text-align: center;
    font-size: $font-size-xl;
}

.loading-skeleton {
    @extend .loading-skeleton;
    margin-bottom: 20px;
    height: 150px;

    @include mobile {
        height: 120px;
    }
}