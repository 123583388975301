@use '../../sass/abstracts' as *;

.container {
    border-radius: 10px;
    border: 4px solid $color-teal;
    padding: $padding-equal-regular;
    background-color: $color-white;
    white-space: pre-line;

    img {
        height: 180px;

        @include mobile {
            height: 150px;
        }
    }

    p {
        font-size: $font-size-regular;
        color: $color-grey-dark;
        margin-top: 20px;
    }

    @include tablet {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
            width: 40%;
            min-width: 40%;
        }

        p {
            text-align: left;
            margin-top: 0px;
        }
    }

    @include mobile {
        display: flex;
        align-items: center;
        padding: 0 10px;
        gap: 10px;

        img {
            width: 25%;
            min-width: 25%;
        }

        p {
            text-align: left;
            margin-top: 1rem;
        }
    }
}