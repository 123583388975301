@use '../../../../sass/abstracts' as *;

.container {        
    display: flex;

    > :global(.modal-content) {
        margin: 20px 30px;
        flex: 1;
    }

    @include mobile {
        padding: 25px;

        > :global(.modal-content) {
            margin: 0;
        }
    }
}

.modal-header-container {
    font-size: $font-size-xl;
    color: $color-grey-dark;
    padding: $padding-horizontal-regular;

    > img,
    > svg {
        margin: 20px 0px;
        width: 100%;
        height: 250px;
        max-height: 250px;
        object-fit: contain;

        @include laptop {
            height: 200px;
            max-height: 200px;
        }

        @include tablet {
            height: 150px;
            max-height: 150px;
        }

        @include mobile {
            height: 150px;
            max-height: 150px;
        }
    }
}

.header-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;

    > button {
        background-color: transparent;
        border: none;
        text-align: center;
        padding: 10px;
   
        > img {
            color: $color-grey-dark;
            width: 24px;
            height: 24px;
        }
    }
}

.description {
    font-size: $font-size-large;
    white-space: pre-line;
    overflow: hidden;
}

.expired-description {
    color: $color-error;
}

.expiry-and-redeem-status {
    p {
        font-family: $font-primary-book;
        font-size: $font-size-regular;
    }
}

.modal-body-container {
    border-top: 1px solid $color-black;
    border-bottom: 1px solid $color-black;
}

.modal-body-subcontainer {
    padding: $padding-horizontal-regular;

    span {
        font-size: $font-size-large;
        color: $color-grey;
    }

    p {
        margin-top: 10px;
        font-size: $font-size-large;
        font-family: $font-primary-book;
        color: $color-grey-dark;
        white-space: pre-line;
        overflow: hidden;
    }
}

.modal-footer-container {
    padding: $padding-horizontal-regular;
}

.footer-description {
    font-size: $font-size-large;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    span:nth-of-type(1) {
        color: $color-grey;
    }

    span:nth-of-type(2) {
        color: $color-grey-dark;
    }

    span:nth-of-type(3) {
        font-family: $font-primary-book;
        color: $color-grey-dark;

        &.failed {
            color: $color-error;
        }
    }
}

.show-code-container {
    width: 100%;
    
    > p > span:nth-of-type(2) {
        color: $color-green;
        letter-spacing: 1rem;
    }
}

.footnote {
    font-size: $font-size-regular;
    font-family: $font-primary-book;
    color: $color-grey-dark;

    span {
        color: $color-green;
    }
}

.redeem-button {
    margin: 20px auto;
    padding: $padding-horizontal-xl;
    font-size: $font-size-xl;
}

.redeem-complete-button {
    display: block;
    margin: 20px auto;
    background-color: $color-white;
    color: $color-green;
    border-radius: $border-radius-regular;
    border: 2px solid $color-green;
    padding: $padding-horizontal-xl;
    font-size: $font-size-xl;

    @include mobile {
        padding: $padding-horizontal-large;
        font-size: $font-size-large;
    }

    @include tablet {
        padding: $padding-horizontal-large;
        font-size: $font-size-large;
    }
}

.error-message {
    color: $color-error;
    text-align: center;
}
