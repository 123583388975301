@use '../../../sass/abstracts' as *;

.container {
    display: flex;
    flex-direction: column;
    border: none;
    border-radius: $border-radius-regular;
    box-shadow: $box-shadow-regular-grey-dark;
    padding: $padding-top-bottom-only-regular;
    background-color: $color-white;
    width: auto;
    min-width: 200px;
    max-width: 200px;
    height: min-content;
    width: 30%;

    @include mobile {
        display: none;
    }

    @include tablet {
        width: 120px;
        min-width: 120px;
        max-width: 120px;
        font-size: $font-size-small;
    }
    
    button {
        display: flex;
        gap: 20px;
        border: none;
        border-bottom: 1px solid $color-grey-light-2;
        background-color: $color-white;
        padding: $padding-horizontal-regular;
        white-space: nowrap;
        align-items: center;

        &:hover {
            background-color: $color-grey-light;
        }

        &.is-active {
            background-color: $color-teal;
            color: $color-white;
        }

        svg {
            min-width: 10px;
            min-height: 10px;
            width: 20px;
            height: 20px;

            @include tablet {
                width: 10px;
                height: 10px;
            }
        }
        
        &.hidden-button-is-active {
            color: $color-teal;
        }

        @include tablet {
            padding: $padding-horizontal-small;
            height: 29px;
            gap: 10px;
        }
    }

    .embedded-button {
        padding-left: 60px;
        background-color: $color-grey-light;
    
        &:hover {
            background-color: $color-grey-light-2;   
        }

        @include tablet {
            padding-left: 30px;
        }
    }
}

.voucher-button {
    div {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    
    svg {
        transition: $transition-fast-ease;
        transform: rotate(180deg);
    }

    svg.is-expanded {
        transform: rotate(0deg);
    }
}