@use '../../sass/abstracts' as *;

.dropdown {
    position: relative;
    border-radius: $border-radius-regular;

    &:hover {
        background-color: $color-grey-light;
    }

    @include mobile {
        padding: 1rem !important;
    }
}

.toggle {
    background-color: transparent !important;
    border: none !important;
    border-radius: $border-radius-regular !important;
    color: $color-black !important;
    display: flex !important;
    overflow: visible !important;
    align-items: center;
    padding: 0px 1rem !important;

    @include tablet {
        padding: 0px 5px !important;
    }
    
    div {
        font-size: $font-size-large;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: $color-white;
        background-color: $color-green;

        @include mobile {
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: $font-size-regular;
        }

        @include tablet {
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: $font-size-regular;
        }
    }
    
    &::after {
        transition: $transition-fast-ease;
        border-top: 0.6rem solid $color-grey-dark !important;
        border-right: 0.6rem solid transparent !important;
        border-left: 0.6rem solid transparent !important;
        margin-left: 1rem !important;

        @include tablet {
            margin-left: 5px !important;
        }
    }
}

.toggle.isOpen,
.embedded-dropdown-toggle.isOpen {
    &::after {
        transform: rotate(180deg);
    }
}

.dropdown-menu {
    transform: translate(0px, 55px) !important;

    @include mobile {
        transform: translate(0px, 40px) !important;
        border: none !important;
        background-color: $color-grey-light !important;
        width: 100%;
    }

    @include tablet {
        transform: translate(0px, 40px) !important;
    }
}

.dropdown-item {
    font-size: $font-size-regular;
    padding: 1rem 4rem 1rem 2rem !important;
    color: $color-grey-dark !important;

    &:active {
        color: $color-teal !important;
        background-color: transparent !important;
    }
}

.embedded-dropdown {
    cursor: pointer;

    &:hover {
        background-color: var(--bs-tertiary-bg);
    }
}

.embedded-dropdown-toggle {
    width: 100%;
    padding: 1rem 4rem 1rem 2rem !important;
    font-size: $font-size-regular !important;
    color: $color-grey-dark !important;

    &:active {
        color: $color-teal !important;
        background-color: transparent !important;
    }
    
    &::after {
        transition: $transition-fast-ease;
        border-top: 0.6rem solid transparent !important;
        border-left: 0.6rem solid $color-grey-dark !important;
        border-bottom: 0.6rem solid transparent !important;
        border-right: 0px !important;
        text-align: center !important;
        position: absolute;
        right: 20px;
    }
}

.embedded-dropdown-toggle:active {
    &::after {
        border-left: 0.6rem solid $color-teal !important;
    }
}

.embedded-dropdown-toggle-menu {
    .dropdown-item {
        &:hover {
            text-decoration-line: underline;
        }
    }

    @include mobile {
        .dropdown-item {
            border: none !important;
            margin-left: 15px;
        }

        border: none !important;
        width: 100%;
        position: relative !important;
        transform: none !important;
        background-color: transparent !important;
    }
}

.mobile-menu {
    background-color: transparent !important;
    border: none !important;
    border-radius: $border-radius-regular !important;
    color: $color-black !important;
    display: flex !important;
    overflow: visible !important;
    align-items: center;
    padding: 0px 1rem !important;
    width: 100% !important;
    
    .initials {
        border-radius: 50%;
        text-align: center;
        color: $color-white;
        background-color: $color-green;

        @include mobile {
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: $font-size-regular;
        }
    }

    .fullname {
        color: $color-green;
        margin-left: 10px;

        @include mobile {
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: $font-size-regular;
        }
    }
    
    &::after {
        transition: $transition-fast-ease;
        border-top: 0.6rem solid $color-grey-dark !important;
        border-right: 0.6rem solid transparent !important;
        border-left: 0.6rem solid transparent !important;
        position: absolute !important;
        right: 15px;
    }
}