@use '../../sass/abstracts' as *;

.container {
    display: flex;
    gap: 10px;
    color: $color-grey-dark;
    font-size: $font-size-large;
    align-items: center;
    margin-top: 20px;
    text-shadow: 1px 1px 0px $color-white;

    :global(.dropdown) {
        > button {
            background-color: $color-white !important;
            opacity: 1 !important;
            color: $color-grey-dark;
            border-radius: 0px;
            font-size: $font-size-large;

            @include mobile {
                font-size: $font-size-regular;
            }
        }
    }

    @include mobile {
        font-size: $font-size-regular;
    }

    > button {
        background-color: transparent;
        border: none;
        color: $color-green;
        text-transform: uppercase;
        margin: 0px 10px;
        text-shadow: 1px 1px 0px $color-white;
    }
}