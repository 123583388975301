@use '../../sass/abstracts' as *;

.button {
    display: block;
    color: $color-white;
    border-radius: $border-radius-regular;
    background-color: $color-green;
    border: none;
    font-size: $font-size-regular;
    padding: $padding-vertical-regular;
    cursor: pointer;
    transition: $transition-slow-ease-in-out;

    @include mobile {
        font-size: $font-size-large !important;
        width: 100%;
    }

    &:hover {
        background-color: $color-green-dark;
    }
}

.outline-button{
    display: block;
    color: $color-black;
    border-radius: $border-radius-regular;
    background-color: $color-grey-light;
    border: 1px solid $color-black;
    font-size: $font-size-regular;
    padding: $padding-vertical-regular;
    cursor: pointer;
    transition: $transition-slow-ease-in-out;

    @include mobile {
        font-size: $font-size-large !important;
        width: 100% !important;
    }

    &:hover {
        background-color: $color-grey-light-2;
    }
}

.ghost-button{
    display: flex;
    align-items: center;
    gap: 20px;
    color: $color-teal;
    font-size: $font-size-large;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: $transition-slow-ease-in-out;

    @include mobile {
        font-size: $font-size-xl !important;
        width: 100% !important;
    }
}