@use './variables' as *;

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-width + 1}) and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$tablet-width + 1}) and (max-width: #{$laptop-width}) {
    @content;
  }
}

@mixin laptop-large {
  @media (min-width: #{$laptop-width + 1}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width + 1}) {
    @content;
  }
}
