@use '../../../sass/abstracts' as *;
@use '../LoginTabContent/LoginTabContentStyles.module' as LoginTabContentStyles;

.container {
    a {
        margin-left: 0px;
        color: $color-success;
    }
}

.terms-paragraph {
    color: $color-black;
    font-family: $font-primary-book;
    margin-top: 20px;
}

.captcha {
    margin: 20px 0px 0px 10px;
}

.error-message {
    margin-left: 10px;
    color: $color-error;
    font-size: $font-size-small;
}