// COLORS
$color-black: #000000;
$color-white: #ffffff;
$color-blue: #418AFF;
$color-grey: #A2A9B0;
$color-grey-2: #A9AfB4;
$color-grey-light: #F3F3F3;
$color-grey-light-2: #e1dede;
$color-grey-dark: #666;
$color-tiffany-blue: #08C4AA;
$color-teal: #00ADBF;
$color-green: #04AB3F;
$color-green-dark: #078232;
$color-klook-orange: #f25d23;
$color-klook-orange-dark: #c94511;
$color-blue-dark: #0a58a5;
$color-blue-dark-2: #04427c;
$color-grab-green: #0e9c49;
$color-grab-green-dark: #0d8c42;

$color-success: #25C696;
$color-warning: #FF9F1C;
$color-error: #FF2636;
$color-placeholder: #C6D1DC;
$color-success-toast-background: #EFF8F8;
$color-progress-background: #EAEDF0;
$color-progress-fill: #ABB6C0;

$color-linear-purple-blue: linear-gradient(90deg, #6200D2 0%, #418AFF 100%);


// Padding
$padding-equal-small: 10px;
$padding-equal-regular: 20px;
$padding-horizontal-small: 5px 10px;
$padding-horizontal-regular: 10px 20px;
$padding-horizontal-large: 15px 40px;
$padding-horizontal-xl: 15px 80px;
$padding-vertical-regular: 15px 10px;
$padding-top-bottom-only-regular: 10px 0px;


// Border Radius
$border-radius-regular: 10px;
$border-radius-top-regular: 10px 10px 0px 0px;


// Box Shadow
$box-shadow-regular-grey: 0px 0px 10px 1px $color-grey;
$box-shadow-regular-grey-dark: 0px 2px 8px 0px $color-grey-dark;

// Transition
$transition-fast-ease: 0.2s;
$transition-slow-ease-in-out: 0.5s ease-in-out;


// FONT
$default-font-size: 1.6rem;
$font-size-small: 12px;
$font-size-regular: 16px;
$font-size-large: 20px;
$font-size-xl: 32px;
$font-size-2xl: 60px;

$font-size-small-mobile: 8px;
$font-size-regular-mobile: 10px;
$font-size-large-mobile: 15px;
$font-size-xl-mobile: 25px;
$font-size-2xl-mobile: 35px;

$font-primary-book: 'foundry-sterling-book', 'sans-serif';
$font-primary-medium: 'foundry-sterling-medium', 'sans-serif';
$font-primary-demi: 'foundry-sterling-demi', sans-serif;
$font-primary-extra-bold: 'foundry-sterling-extra-bold', sans-serif;


// Screen sizes
$mobile-width: 480px;
$tablet-width: 768px;
$laptop-width: 1024px;
$desktop-width: 1280px;