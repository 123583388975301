@use '../../sass/abstracts' as *;

.container {
    background-color: $color-grey-light;
}

.content-container {
    display: flex;
    position: relative;
    width: 60%;
    margin: 0px auto;
    gap: 50px;
    padding: 20px 0px;

    > img {
        margin-left: auto;

        @include mobile {
            margin-left: 0px;
        }
    }

    @include mobile {
        width: 100%;
        margin: 0px 5px;
        gap: 5px;
        justify-content: space-between;
    }
    
    @include tablet {
        width: 90%;
        gap: 30px;
        justify-content: space-between;
    }

    @include laptop {
        width: 90%;
        gap: 30px;
        justify-content: space-between;
    }
}

.footer-logo-image {
    height: 70px;
    width: auto;
    content: url('../../assets/images/cooper-vision-logo.webp');

    @include tablet {
        content: url('../../assets/images/cooper-vision-logo-mobile.webp');
    }
    @include mobile {
        height: 50px;
        content: url('../../assets/images/cooper-vision-logo-mobile.webp');
    }
}

.content-subcontainer {
    display: flex;
    flex-direction: column;

    > div {
        padding: $padding-horizontal-small;
        font-size: $font-size-regular;
        color: $color-grey-dark;
        line-height: $font-size-large;

        > span {
            color: $color-grey-dark;
            font-family: $font-primary-book;
        }
    }

    > a {
        padding: $padding-horizontal-small;
    }

    @include mobile {
        > div {
            font-size: $font-size-regular-mobile;
            line-height: $font-size-regular-mobile;
        }
    }
}

.copyright-container {
    width: 60%;
    margin: 0px auto; 
    padding: $padding-horizontal-small;
    padding-bottom: 10px;
    color: $color-grey-dark;
    font-family: $font-primary-book;
    line-height: $font-size-regular;

    a {
        color: $color-teal;
        text-decoration-line: none;
    }

    @include tablet {
        width: 90%;
    }

    @include mobile {
        width: 90%;
    }
}

.arrow-up-button {
    position: absolute;
    top: 0px;
    right: 12px;
    transform: translateY(-50%);
    border: none;
    border-radius: 50%;
    background-color: $color-white;
    box-shadow: $box-shadow-regular-grey;
    padding: $padding-equal-small;

    svg {
        fill: $color-teal;
        height: 30px;
        width: 30px;
    }
}
