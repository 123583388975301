@use '../abstracts/variables' as *;

.loading-skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    width: 100%;
    height: 100%;
    min-height: 20px;
}

@keyframes skeleton-loading {
    0% {
      background-color: #c2cfd6;
    }
    100% {
      background-color: #f0f3f5;
    }
  }