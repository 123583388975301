@use '../../sass/abstracts' as *;

.container {
    padding: $padding-horizontal-regular;
    background-image: url('../../assets/images/background-image-v2.webp');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;

    > h1 {
        font-size: $font-size-2xl;
        color: $color-teal;
        font-family: $font-primary-extra-bold;
        text-align: center;
        margin: 40px auto;

        @include mobile {
            font-size: $font-size-xl;
        }
    }
}

.subcontainer {
    width: 40%;
    margin: 20px auto;
    border-radius: $border-radius-regular;
    background-color: $color-white;
    padding: 40px 20px;
    box-shadow: $box-shadow-regular-grey-dark;
    margin: 20px auto;

    @include mobile {
        width: 90%;
    }

    >  button {
        width: 60%;
        margin: 20px auto 0px;
        font-size: $font-size-large;
    }

}

.form-group {
    input {
        padding: $padding-horizontal-regular;
        border: 4px solid $color-grey-light;
        width: 100%;
        font-size: $font-size-large;
        color: $color-grey-dark;

        &::placeholder {
            color: $color-grey;
            font-family: $font-primary-book;
        }
    }

    label {
        margin-left: 10px;
        margin-top: 10px;
        color: $color-grey-dark;

        @include mobile {
            font-size: $font-size-regular;
        }
    }

    &:first-of-type label {
        margin-top: 0px;
    }

    input:global(.is-invalid) {
        border-color: $color-error;
    }

    :global(.invalid-feedback) {
        color: $color-error;
        margin-left: 10px;
    }
}

.form-feedback{
    color: $color-error;

    @include mobile {
        font-size: $font-size-regular;
    }
}

.error {
    text-align: center;
    color: $color-error;
    margin: 20px auto 0px;

    @include mobile {
        font-size: $font-size-regular;
    }
}

.button-container {
    margin: 20px 0;
    display: flex;
    justify-content: center;

    > button {
        width: 50%;
        font-size: $font-size-large;

        @include mobile {
            display: flex;
            width: 100%;
            justify-content: center;
        }
    }    
}