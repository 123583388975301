@use '../abstracts' as *;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: $font-primary-demi;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* //1rem = 10px 10px/16px = 62.5% */
  margin: 0 auto;
  
  @include mobile {
    font-size: 40%;
  }
}