@use '../../../sass/abstracts' as *;

.container {
    position: relative;
    background-image: url('../../../assets/images/background-image-v2.webp');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
   
    > div:last-of-type {
        padding-bottom: 100px;
    }
}