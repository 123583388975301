@use '../../sass/abstracts' as *;

.container {
    padding: 50px 0px 100px;
    background-image: url('../../assets/images/background-image-v2.webp');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

    > h1 {
        color: $color-green;
        font-size: $font-size-xl;
        text-align: center;
        text-shadow: 1px 1px 0px $color-white;
    }

    > p {
        color: $color-grey-dark;
        font-size: $font-size-large;
        text-align: center;
        font-family: $font-primary-book;
        text-shadow: 1px 1px 0px $color-white;
    }
}

.subcontainer {
    display: flex;
    margin: 20px auto 0px;
    gap: 40px;
    width: 100%;
    padding: 0px 20px;

    @include tablet {
        gap: 20px;
    }

    @include laptop-large {
        width: 90%;
        padding: 0px;
    }

    @include desktop {
        width: 70%;
        padding: 0px;
    }
}
