@use '../../sass/abstracts' as *;

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.accordion-item {
    background-color: $color-teal !important;
    border-radius: 10px !important;
    border: none !important;
    z-index: 0;
}

.accordion-header {
    button {
        background-color: $color-teal !important;
        box-shadow: none !important;
        border-radius: 10px !important;
        color: $color-white !important;
        font-size: $font-size-large;
        line-height: $font-size-large;
        padding: $padding-horizontal-regular;        

        // counter-reset property is declared in _FAQSectionStyles.module.scss
        &::before {
            color: $color-white;
            white-space: pre;
            counter-increment: accordion;
            content: 'Q' counter(accordion) '. ';
        }

        // :global is used here to select .collapsed without the hash because this className is from reactstrap
        &:not(:global(.collapsed)) > div svg {
            transform: rotate(0deg);
        }

        // White circle for the SVG
        > div {
            border-radius: 50%;
            background-color: $color-white;
            margin-left: auto;

            // Custom chevron here to control fill color
            svg {
                transition: $transition-fast-ease;
                fill: $color-teal;
                width: 30px;
                height: 30px;
                transform: rotate(180deg);
            }
        }
            
        // Hiding default reactstrap chevron
        &::after {
            display: none;
        }

        @include tablet {
            padding-bottom: 0;
        }

        @include mobile {
            font-size: $font-size-large-mobile;
            padding-bottom: 0;
        }
    }
}

.accordion-body {
    > div {
        border-radius: 10px;
        background-color: $color-teal;

        // Actual content container
        > div {
            text-align: start;
            border-radius: 10px;
            padding: $padding-horizontal-regular;
            font-size: $font-size-regular;
            background-color: $color-white;
            color: $color-grey-dark;
            white-space: pre-line;
            overflow: hidden;
        }
    }
}