@use '../../../../sass/abstracts' as *;

.container {
    width: 60%;
    margin: 0px auto;
    padding: 100px 0px 0px;
    text-align: center;

    h2 {
        text-align: center;
        font-size: $font-size-2xl;
        color: $color-teal;
        font-family: $font-primary-extra-bold;
        text-shadow: 1px 1px 0px $color-white;
    }

    > button {
        margin: 40px auto 0px;
        font-size: $font-size-large;
        min-width: 250px;

        @include mobile {
            min-width: 0;
        }
    }

    @include laptop {
        width: 85%;
    }

    @include tablet {
        padding: 75px 0px 0px;

        h2 {
            font-size: $font-size-xl;
            padding-bottom: 10px;
        }
    }

    @include mobile {
        padding: 50px 0px 0px;

        p {
            font-size: $font-size-small;
        }

        h2 {
            font-size: $font-size-2xl-mobile;
            padding-bottom: 10px;
        }
    }
}

.card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, auto));
    gap: 20px;

    a {
        color: $color-green;
        text-decoration-line: none;

        &:hover {
            text-decoration-line: underline;
        }
    }

    span {
        color: $color-green;
    }

    @include tablet {
        grid-template-columns: 125%;
        justify-content: center;
    }

    @include mobile {
        grid-template-columns: 125%;
        justify-content: center;
    }
}